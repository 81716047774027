.subSuccess {
  text-align: center;
}
.centerAlign {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.primaryHeading {
  font-size: 22px;
  font-weight: 500;
  padding: 0;
  margin: 20px 0 10px 0;
}
.secondaryHeading {
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 2px 0;
  color: #8d9ba3;
}
.backPreferences {
  border: 1px solid #03a86b;
  border-radius: 2px;
  background-color: #02b875;
  /* box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.12), 0 0 1px 0 rgba(24,50,71,0.6), 0 1px 0 0 #02935E; */
  color: #fff;
  padding: 12px 21px;
  margin-top: 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.manageOthersbtn{
  border: 1px solid #03a86b;
  border-radius: 2px;
  background-color: #fff;
  /* box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.12), 0 0 1px 0 rgba(24,50,71,0.6), 0 1px 0 0 #02935E; */
  padding: 12px 21px;
  margin-top: 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  cursor: pointer;
    color: #02b875;
    margin: 0 20px 0 0;
}