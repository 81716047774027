.statusBanner {
  position: fixed;
  background: #d5f7d5;
  padding: 4px 18px 8px;
  min-width: 200px;
  text-align: center;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border-radius: 7px;
  font-size: 14px;
}
.statusSuccess {
  background: #d5f7d5;
  color: green;
}

.statusError {
  background: #ffd0d0;
  color: #ff0000;
}
