.listWrapper {
  list-style-type: none;
  padding: 0px;
  margin: 30px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  font-family: Inter Thin;

}

.listWrapper li {
  margin-bottom: 20px;
  width: 50%;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Inter Thin;
}

.container span {
  font-weight: 600;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container input:checked ~ .checkmark {
  background-color: #f4511e;
  transition: ease 0.2s;
}

h2 {
  font-size: 16px;
  font-family: Inter Thin;
}
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: #eee;
  border-radius: 2px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 2px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.subcContent {
  margin-bottom: 15px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyWrapper {
  background: white;
  width: 100%;
  width: 750px;
  margin: 0 auto;
  font-size: 13px;
  padding: 30px 10%;
  height: 100%;
  overflow: auto;

}
.heading {
  color: #505a63;
  text-align: center;
  font-size: 40px;
  margin: 30px;
  font-weight: 400;
  line-height: normal;
}
.subHeading {
  font-size: 16px;
  color: #505a63;
  text-align: center;
  font-weight: 300;
}

.emailHeading {
  color: #475867;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
  display: inline-block;
}

.btnSet {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  border-top: 1px solid #ececec;
  padding: 18px 0;
  position: sticky;
  bottom: 0;
  background: #fff;
}

.loader{
  display: flex;
  justify-content: center;
  min-height: 100px;
  align-items: center;
  font-size: 20px;
    color: grey;
}

@font-face {
  font-family: Inter Thin;
  src: url('../../../../public/font/Inter.ttf');
}
