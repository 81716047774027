.bodyWrapper {
  background: white;
  /* box-shadow: 0 2px 4px 2px rgba(219, 219, 219, 0.5); */
  /* border: 1px solid #e1e1e1; */
  width: 100%;
  width: 700px;
  margin: 0 auto;
  font-size: 13px;
  padding: 0px 10% 0px;
  max-height: calc(100vh - 140px);
  /* overflow: auto; */
  margin-bottom: 70px;
}
.wholeBodyWrapper{
  background: white;
  /* box-shadow: 0 2px 4px 2px rgba(219, 219, 219, 0.5);
  border: 1px solid #e1e1e1; */
  width: 100%;
  width: 700px;
  margin: 0 auto;
  font-size: 13px;
  padding: 30px 10% 0px;
  max-height: 100vh;
  /* overflow: auto; */
}
.logoWrapper {
  display: flex;
  justify-content: space-between;
}

.logoWrapper img {
  width: 120px;
}

.heading {
  color: #f4511e;
  text-align: center;
  font-size: 40px;
  margin: 30px;
  font-weight: 400;
  line-height: normal;
  font-family: Larken DEMO Thin;
  font-weight: 200;

}

.subHeading {
  font-size: 16px;
  color: #505a63;
  text-align: center;
  font-weight: 300;
  font-family: z;
}

.emailHeading {
  color: #475867;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
  display: inline-block;
  font-family: Inter Thin;

}

.btnSet {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  position: sticky;
  bottom: 90px;
  background: #fff;
}

.errorMsg {
  color: #ff0000;
  font-size: 12px;
}

.redText {
  color: rgb(252, 76, 2);
}

.footerBlock {
  /* position: fixed; */
  /* width: 850px; */
  margin: auto;
  padding: 7px;
  font-family: Inter Thin;
  font-size: small;
}

.fixedBlock{
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fee9e1;
  z-index: 10;
}

@font-face {
  font-family: Larken DEMO Thin;
  src: url('../../../public/font/LarkenDEMO-Thin.otf') format("opentype");
}

@media(max-width: 768px){
  .fixedBlock{
   position: fixed;
  bottom:0;
  width: 100%;
  }
  }

