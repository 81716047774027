@font-face {
  font-family: Inter Thin;
  src: url('../public/font/Inter.ttf');
}

body {
  margin: 0;
  font-family: Inter Thin;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica Neue, Arial, sans-serif, "Larken"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}



input {
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  height: 33px;
  border: 1px solid #cfd7df;
  box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
  padding-left: 10px;
  font-size: 14px;
  outline: none;
  opacity: 0.4;
  color: #12344d;
}

.linkBtn {
  text-align: center;
  color: #f4511e;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  margin-bottom: 30px;
  cursor: pointer;
  border: none;
  background: #fff;
}
.linkBtn.disabled{
  cursor: no-drop;
}
.verticalCenter {
  display: flex;
  justify-content: center;
  /* padding-top: 20px; */
  align-items: center;
  height: 100vh;
}

.btn {
  border-radius: 4px;
  border-color: #f4511e;
  display: inline-block;
  color: #f4511e;
  line-height: 20px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: #f5f7f9;
  cursor: pointer;
  margin-left: 15px;
}

.btn-primary {
  border: 1px solid #f4511e;
  background: #f4511e;
  color: #fff;
  margin-left: 15px;
}
.btn-cancel{
    border-radius: 4px;
    border-color: #f4511e;
    display: inline-block;
    color: #f4511e;
    line-height: 20px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    background-color: #f5f7f9;
    cursor: pointer;
    margin-left: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
